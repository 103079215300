<template>
  <main id="Basic">
    <CContainer>
      <CCard>
        <CCardHeader>
          <h5 class="mb-0">{{ $t('Navigation.System/Tracking') }}</h5>
        </CCardHeader>
        <CCardBody>
          <CTabs variant="pills" :vertical="{ navs: 'col-lg-2 col-md-3 col-sm-12 col-12 mb-4', content: 'col-lg-10 col-md-9 col-sm-12 col-12' }">
            <CTab v-if="Features.includes('GA')" :title="$t('System/Tracking.GA')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Tracking.GA') }}
              </h5>
              <hr>
              <CRow>
                <CCol col="6">
                  <CInput :horizontal="{label: 'col-sm-5 col-lg-3', input: 'col-sm-7 col-lg-9'}" v-model="TrackingData.GA" :label="$t('System/Tracking.Labels.GA')" />
                </CCol>
              </CRow>
              <div class="bg-light p-3 mb-3">
                <code>
                  &lt;script type="text/javascript">
                  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
                  ga('create', '<span class="text-info font-weight-bold">UA-12345678-00</span>', 'auto');
                  ga('send', 'pageview');
                  &lt;/script>
                </code>
              </div>
              <div class="d-flex justify-content-between">
                <CButton @click="SetData()" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab :title="$t('System/Tracking.GTM')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Tracking.GTM') }}
              </h5>
              <hr>
              <CRow>
                <CCol col="6">
                  <CInput :horizontal="{label: 'col-sm-5 col-lg-3', input: 'col-sm-7 col-lg-9'}" v-model="TrackingData.GTM" :label="$t('System/Tracking.Labels.GTM')" />
                </CCol>
              </CRow>
              <div class="bg-light p-3 mb-3">
                <code>
                  &lt;script>
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','<span class="text-info font-weight-bold">追蹤代碼id</span>');
                  &lt;/script>
                </code>
              </div>
              <div class="d-flex justify-content-between">
                <CButton @click="SetData()" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab :title="$t('System/Tracking.FP')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Tracking.FP') }}
              </h5>
              <hr>
              <CRow>
                <CCol col="6">
                  <CInput :horizontal="{label: 'col-sm-5 col-lg-3', input: 'col-sm-7 col-lg-9'}" v-model="TrackingData.FP" :label="$t('System/Tracking.Labels.FP')" />
                </CCol>
                <CCol col="6" />
              </CRow>
              <div class="d-flex justify-content-between">
                <CButton @click="SetData()" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab v-if="Features.includes('GMC')" :title="$t('System/Tracking.GMC')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Tracking.GMC') }}
              </h5>
              <hr>
              <CRow>
                <CCol col="12">
                  <CInput :value="`https://market.qsweb.app/application/web/gmcProductFeed/${Token}`" :label="$t('System/Tracking.Labels.GMC')" disabled>
                    <template #append>
                      <CButton type="button" color="info" @click="CopyToClipboard(`https://market.qsweb.app/application/web/gmcProductFeed/${Token}`)">
                        <CIcon name="cil-copy" class="c-icon-custom-size mr-2 my-0" />{{ $t('Global.Copy') }}
                      </CButton>
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </CTab>
            <CTab v-if="Features.includes('GAW')" :title="$t('System/Tracking.GAW')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Tracking.GAW') }}
              </h5>
              <hr>
              <CRow>
                <CCol col="6">
                  <CInput :horizontal="{label: 'col-sm-5 col-lg-3', input: 'col-sm-7 col-lg-9'}" v-model="TrackingData.GAW.TrackingNum" :label="$t('System/Tracking.Labels.GAWTracking')" />
                  <CInput :horizontal="{label: 'col-sm-5 col-lg-3', input: 'col-sm-7 col-lg-9'}" v-model="TrackingData.GAW.BrowseTag" :label="$t('System/Tracking.Labels.GAWBrowseTag')" />
                  <CInput :horizontal="{label: 'col-sm-5 col-lg-3', input: 'col-sm-7 col-lg-9'}" v-model="TrackingData.GAW.PurchaseTag" :label="$t('System/Tracking.Labels.GAWPurchaseTag')" />
                </CCol>
              </CRow>
              <div class="bg-light p-3 mb-3">
                <code>
                  &lt;script type="text/javascript">
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments)};
                  gtag('js', new Date());
                  gtag('config', '<span class="text-info font-weight-bold">AW-016888888</span>');
                  gtag('event', 'conversion', {'send_to': '<span class="text-info font-weight-bold">AW-016888888</span>/<span class="text-info font-weight-bold">Oll_aidecS0T5o3r2ekN</span>'});
                  &lt;/script>
                </code>
              </div>
              <div class="d-flex justify-content-between">
                <CButton @click="SetData()" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab v-if="Features.includes('GRM')" :title="$t('System/Tracking.GRM')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Tracking.GRM') }}
              </h5>
              <hr>
              <CRow>
                <CCol col="6">
                  <CInput :horizontal="{label: 'col-sm-5 col-lg-3', input: 'col-sm-7 col-lg-9'}" v-model="TrackingData.GRM" :label="$t('System/Tracking.Labels.GRM')" />
                </CCol>
              </CRow>
              <div class="bg-light p-3 mb-3">
                <code>
                  &lt;script type="text/javascript">
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments)};
                  gtag('js', new Date());
                  gtag('config', 'AW-1234567890');
                  gtag('event', 'conversion', { 'send_to': '<span class="text-info font-weight-bold">AW-0168888890</span>' });
                  &lt;/script>
                </code>
              </div>
              <div class="d-flex justify-content-between">
                <CButton @click="SetData()" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab v-if="Features.includes('YAd')" :title="$t('System/Tracking.YAd')">
              <h5 class="d-flex justify-content-between">
                {{ $t('System/Tracking.YAd') }}
              </h5>
              <hr>
              <CRow>
                <CCol col="6">
                  <CInput :horizontal="{label: 'col-sm-5 col-lg-3', input: 'col-sm-7 col-lg-9'}" v-model="TrackingData.YAd.ProjectID" :label="$t('System/Tracking.Labels.YAdProjectID')" />
                  <CInput :horizontal="{label: 'col-sm-5 col-lg-3', input: 'col-sm-7 col-lg-9'}" v-model="TrackingData.YAd.TrackingID" :label="$t('System/Tracking.Labels.YAdTrackingID')" />
                </CCol>
              </CRow>
              <div class="bg-light p-3 mb-3">
                <code>
                  &lt;script type="text/javascript">
                  (function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'<span class="text-info font-weight-bold">廣告項目ID</span>','properties':{'pixelId':'<span class="text-info font-weight-bold">廣告追蹤ID</span>'}});
                  var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded")  {return}
                  try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p) {y([p])}
                  ;y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");
                  &lt;/script>
                </code>
              </div>
              <div class="d-flex justify-content-between">
                <CButton @click="SetData()" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
          </CTabs>
          <CElementCover v-if="(Loading === true)" :opacity="0.75">
            <CSpinner color="success"/>
          </CElementCover>
        </CCardBody>
      </CCard>
    </CContainer>
  </main>
</template>

<route>
{
  "meta": {
    "label": "行銷與追蹤"
  }
}
</route>

<script>
export default {
  name: 'Tracking',
  layout: 'manage',
  components: {

  },
  data () {
    return {
      Features: [],
      Loading: false,
      Token: '',
      TrackingData: {
        GA: '',
        GTM: '',
        FP: '',
        GAW: {
          TrackingNum: '',
          BrowseTag: '',
          PurchaseTag: ''
        },
        GRM: '',
        YAd: {
          ProjectID: '',
          TrackingID: ''
        }
      }
    }
  },
  mounted() {
    this.Loading = true
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
      this.Loading = false
    }).catch((err) => {
      this.$Progress.fail()
      this.Loading = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.System.Tracking
      return Promise.all([
        this.GetData(),
        this.GetToken()
      ])
    },
    GetToken() {
      return this.$store.dispatch('InnerRequest', {
        url: '/web/token2',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project
        }
      }).then(({data}) => {
        this.Token = data.Data
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/Tracking',
        method: 'post'
      }).then(({data}) => {
        if (Object.keys(data).length > 0) this.TrackingData = data
        return true
      }).catch((err) => {
        throw err
      })
    },
    SetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/Tracking',
        method: 'post',
        data: {Tracking: this.TrackingData}
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    CopyToClipboard (string) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/CopySuccess'),
          type: 'success'
        })
        return navigator.clipboard.writeText(string)
      }
      return Promise.reject('The Clipboard API is not available.');
    }
  }
}
</script>
